import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContactTemplate from '../../templates/pages/contact';
import Layout from '../../components/Layout/main';

const Contact = ( { data } ) => {
	const { file: { childMarkdownRemark: { frontmatter: { locations } } } } = data;

	return (
		<Layout title="contact us">
			<Helmet>
				<link rel="stylesheet" href="https://kinetic-styles.netlify.app/Styles.css" />
			</Helmet>
			<ContactTemplate locations={ locations } />
		</Layout>
	);
};

Contact.propTypes = { data: PropTypes.object };

export default Contact;

export const query = graphql`
	query Contact {
		file(relativePath: {eq: "pages/contact.md"}) {
			childMarkdownRemark {
				frontmatter {
					locations {
						address {
							phone
							fax
							street
							suite
							city
							state
							zip
						}
						image {
							childImageSharp {
								fluid(maxWidth: 1400, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
	`;
